export const observerExists = (w) => {
    return w && 
            "IntersectionObserver" in w &&
            "IntersectionObserverEntry" in w &&
            "intersectionRatio" in w.IntersectionObserverEntry.prototype;
}

export const attachObserver = (div, fnIntersect, fnUnintersect, removeOnTrigger = false) => {
    const options = {
        rootMargin: '0px',
        threshold: [0, 0.125, 0.25]
    };

    const observeCb = (entries, self) => {
        if (entries[0].isIntersecting) {
            fnIntersect && fnIntersect();
            if (removeOnTrigger && !!self) {
                self.disconnect();
            }
        } else {
            fnUnintersect && fnUnintersect();
        }
    }
  
    let observer = new IntersectionObserver(observeCb, options);
    observer.observe(div);
    return observer;
}

export const hasClass = function(elem, className) {
    const clazzes = String(elem.target.className).toLowerCase().split(" ");
    const clazz = className.toLowerCase();
    return clazzes.indexOf(clazz) >= 0;
};

export const findParentBy = (e, criteria) => {
    if (!e) {
        return null;
    }

    let prop = "";

    if (typeof criteria === "string") {
        if (criteria.indexOf(".") === 0) { prop = "className"; criteria = criteria.substring(1); } else
        if (criteria.indexOf("#") === 0) { prop = "id"; criteria = criteria.substring(1); }
    } else {
        return null;
    }

    var t = e.target ? e.target : e;
    while (t && !(t[prop] && t[prop].indexOf(criteria) >= 0)) {
        if (!t.parentNode || t.tagName === "HTML") { return null; }
        t = t.parentNode;
    }
    return t;
}

export const getCookie = (name) => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

export const setCookie = (name, value, days) => {
    var d = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000 * days));
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}
