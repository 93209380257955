import React, { Component } from "react";
import logo from "../images/arrunada-logo-horizontal.svg";
import i18Data from '../../content/intl/labels.json';

class Footer extends Component {

    componentDidMount() {
        this.windowRef = window;
    }

    render() {
        const { lang } = this.props;
        const i18 = i18Data[ lang ];

        const twitter = i18.siteMetadata.twitter;
        const email = i18.siteMetadata.email;
        const phone = i18.siteMetadata.phone;

        return (

            <footer className="page-footer">
                
                <span className="pill">
                    {i18.labels.phone}: <a href={`tel:${phone}`}>{phone}</a>
                </span>
                
                <span className="pill">
                    {i18.labels.email}: <a href={`mailto:${email}`}>{email}</a>
                </span>

                <span className="pill">
                    {i18.labels.twitter}: <a target="_blank" rel="noreferrer" href={`https://twitter.com/${twitter}`}>@{twitter}</a>
                </span>

                <img src={logo} className="logo" alt={i18.siteMetadata.defaultTitle} width="204" height="24" />

            </footer>

        )
    }
}

export default Footer