/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import i18Data from '../../content/intl/labels.json';

function SEO({ description, lang, meta, keywords, title }) {

	const i18 = i18Data[ lang ];

	const metaDescription = description ? description : i18.siteMetadata.description;
	const defaultTitle = i18.siteMetadata.defaultTitle;
	const twitter = i18.siteMetadata.twitter;
	const extraMeta = meta || [];

	return (
		<Helmet
			htmlAttributes={{
			lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
			{
				name: `description`,
				content: metaDescription,
			},
			{
				property: `og:title`,
				content: title,
			},
			{
				property: `og:description`,
				content: metaDescription,
			},
			{
				property: `og:type`,
				content: `website`,
			},
			{
				name: `twitter:card`,
				content: `summary`,
			},
			{
				name: `twitter:creator`,
				content: twitter || ``,
			},
			{
				name: `twitter:title`,
				content: title,
			},
			{
				name: `twitter:description`,
				content: metaDescription,
			},
			{
				name: `theme-color`,
				content: "#f3f3f3"
			},
			{
				name: `viewport`,
				content: `width=device-width, initial-scale=1, maximum-scale=5`
			}
			].concat(extraMeta)}
		/>
	)
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;


