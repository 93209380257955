import React, { Component } from "react"
import { Link } from "gatsby";
import { observerExists } from './helpers/domHelpers';

import logo from "../images/arrunada-logo-main.svg";
import logoTop from "../images/arrunada-logo-horizontal.svg";
import menuItemsI18 from '../../content/intl/menu.json';
import { genPrefix } from "./helpers/helpers";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { menuOpened: false, smallLogo: false };
        this.onToggleMenu = this.onToggleMenu.bind(this);
    }

    componentDidMount() {
        this.windowRef = window;
        this._logoObserver = this.setLogoObserver(window);
    }

    componentWillUnmount() {
        if (this._logoObserver) {
            this._logoObserver.unobserve(this.bigLogo);
        }
    }

    onToggleMenu() {
        this.setState({ menuOpened: !this.state.menuOpened });
    }

    setLogoObserver(w) {
        if (observerExists(w)) {

            const options = {
                rootMargin: "0px 0px 0px 0px",
                threshold: [0.0, 0.05, 0.1, 0.15, 0.25, 0.5, 0.75, 1.0]
            };

            const observeCb = (entries) => {
                const prevSmallLogo = this.state.smallLogo,
                    isPartiallyVisible = entries[0].intersectionRatio < 1;

                if (prevSmallLogo !== isPartiallyVisible) {
                    this.setState({ smallLogo: isPartiallyVisible });
                }
            }
          
            let observer = new IntersectionObserver(observeCb, options);
            observer.observe(this.bigLogo);
            return observer;
        }

        return null;
    }

    render() {
        const { pageContext: { locales, lang }, siteTitle, translations } = this.props;
        const menuOpened = this.state.menuOpened;
        const homeUrl = genPrefix(lang, false) || "/";
        const menuLinks = menuItemsI18.map(item => item[ lang ]);

        const MenuItem = (url, label) => {
            return(
                <li key={url}>
                    <Link activeClassName="active" partiallyActive={url !== homeUrl} to={url}>{label}</Link>
                </li>
            )
        }

        const langOptions = locales.map(local => {
            if (translations[ local ]) {
                return(                                
                    <li key={local} className={local === lang ? "active" : ""}>
                        <Link to={translations[ local ].url === "" ? "/" : translations[ local ].url }>{local}</Link>
                    </li>
                )
            } else {
                return(                                
                    <li key={local} className="disabled">{local}</li>
                )
            }
        }
        )

        return (

            <header className={`page-header ${this.state.smallLogo ? "small-logo" : ""} ${menuOpened ? "menu-opened" : ""}`}>
                
                <div id="page-header-top" className="page-header-top">
                    <div className="contained pos-rel">
                        <div className="page-header-top-contained">
                            <div className="logo-holder-top">
                                <Link to={homeUrl}><img src={logoTop} alt={siteTitle} width="300" height="35" /></Link>
                            </div>

                            <ul className="center-top-nav" role="navigation">
                                { menuLinks.map(m => MenuItem(m.url, m.label)) }
                            </ul>

                            <div className="mobile-menu non-selectable">
                                <div id="mobile-menu-button" className="mobile-menu-button non-selectable" onClick={this.onToggleMenu.bind(this)} aria-hidden="true">
                                    <span className="btn">
                                        <span className="mmb mmb1"></span><span className="mmb mmb2"></span><span className="mmb mmb3"></span>
                                    </span>
                                </div>
                            </div>
                            
                            { menuOpened &&
                            <div className="mobile-menu-options">
                                <ul id="ul-mobile-menu" className="ul-mobile-menu">
                                    <li>
                                        <ul className="langs">
                                            {langOptions}
                                        </ul>
                                    </li>
                                    { menuLinks.map(m => MenuItem(m.url, m.label)) }
                                </ul>
                                <span className="close-menu" aria-hidden="true" onClick={_ => this.setState({ menuOpened: false }) }></span>
                            </div>
                            }

                            <ul className="top-langs">
                                {langOptions}
                            </ul>
                        </div>
                        

                    </div>

                    
                </div>

                <div className="logo-holder">
                  <div className="logo-holder-initial">
                        <Link to={homeUrl}>
                            <img ref={(t) => { this.bigLogo = t }} src={logo} alt={siteTitle} width="264px" height="166px" />                      
                        </Link>
                  </div>
                </div>
                
            </header>

        )
    }
}

export default Header;
/*

export default function MyHeader(props) {
    return (
      <StaticQuery
        query={graphql`
          query {
            logoMain: file(relativePath: { eq: "arrunada-logo-main.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 528) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
          }
        `}
        render={data => <Header data={data} {...props} />}
      />
    )
  }
*/